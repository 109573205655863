<template>
  <base-dialog v-model="dialog" class="justify-center layout px-0" max-width="800px">
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="6" class="pb-0">
            <label class="detail-title">Nome fantasia*</label>
            <mf-text-input
              v-model="formatters.fantasy_name"
              class="mb-0"
              outlined
              :errors-messages="$v.formatters.fantasy_name.$error ? 'Campo obrigatório.' : ''"
            />
          </v-col>
          <v-col cols="6" class="pb-0">
            <label class="detail-title">Razão social*</label>
            <mf-text-input
              v-model="formatters.company_name"
              class="mb-0"
              outlined
              :errors-messages="$v.formatters.company_name.$error ? 'Campo obrigatório.' : ''"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="pb-0">
            <label class="detail-title">Endereço*</label>
            <mf-text-input v-model="formatters.address" class="mb-0" outlined :errors-messages="$v.formatters.address.$error ? 'Campo obrigatório.' : ''" />
          </v-col>
          <v-col cols="6" class="pb-0">
            <label class="detail-title">CNPJs*</label>
            <mf-text-input v-model="formatters.cnpjs" class="mb-0" outlined :errors-messages="$v.formatters.cnpjs.$error ? 'Campo obrigatório.' : ''" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="pb-0">
            <label class="detail-title">Email*</label>
            <mf-text-input v-model="formatters.email" class="mb-0" outlined :errors-messages="emailErrorMessage" />
          </v-col>
          <v-col cols="6" class="pb-0">
            <label class="detail-title">Hotsite*</label>
            <mf-text-input v-model="formatters.hotsite" class="mb-0" outlined :errors-messages="$v.formatters.hotsite.$error ? 'Campo obrigatório.' : ''" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pb-0">
            <label class="detail-title">Modelo*</label>
            <v-select v-model="selectedModel" :items="models" outlined :error-messages="$v.selectedModel.$error ? 'Campo obrigatório.' : ''" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <mf-action-buttons
              class="mt-2"
              :primary-button="{
                text: 'Configurar Termo',
                action: validateAndSetFields,
                isVisible: true,
                isDisabled: false,
                isLoading: false
              }"
              :cancel-button="{
                text: 'Cancelar',
                action: closeDialog,
                isVisible: true,
                isDisabled: false,
                isLoading: false
              }"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </base-dialog>
</template>

<script>
// eslint-disable-next-line
import TribancoTerm from 'raw-loader!@/modules/accounts/assets/terms/tribanco.md'
// eslint-disable-next-line
import MercafacilTerm from 'raw-loader!@/modules/accounts/assets/terms/mercafacil.md'
import format from 'string-format'
import { required, email } from 'vuelidate/lib/validators'
import BaseDialog from '../../../../components/atomic-components/atoms/BaseDialog.vue'
/**
 * Form dialog to set up account term
 */
export default {
  name: 'DefaultTerms',
  components: {
    BaseDialog
  },
  props: {
    /**
     * @model
     */
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: this.value,
      formatters: {
        company_name: '',
        fantasy_name: '',
        address: '',
        cnpjs: '',
        email: '',
        hotsite: ''
      },
      selectedModel: '',
      emailErrorMessage: ''
    }
  },
  computed: {
    models() {
      return [
        { text: 'Modelo Básico Mercafacil', value: 'mercafacil' },
        { text: 'Modelo Básico Tribanco', value: 'tribanco' }
      ]
    },
    modelTerms() {
      return {
        tribanco: TribancoTerm,
        mercafacil: MercafacilTerm
      }
    }
  },
  watch: {
    value() {
      this.dialog = this.value
    },
    dialog() {
      /**
       * Atualiza a model
       * @event input
       */
      this.$emit('input', this.dialog)
    }
  },
  methods: {
    setTerm() {
      const term = format(this.modelTerms[this.selectedModel], this.formatters)
      this.$emit('set-term', term)
    },
    validateAndSetFields() {
      this.$v.$touch()
      if (!this.$v.formatters.email.email && this.$v.formatters.email.$error) {
        this.emailErrorMessage = 'Email inválido.'
      } else if (this.$v.formatters.email.$error) {
        this.emailErrorMessage = 'Campo obrigatório.'
      } else {
        this.emailErrorMessage = ''
      }
      if (this.$v.$error) {
        this.$snackbar({ message: 'Configure os campos corretamente.' })
        return
      }
      this.setTerm()
    },
    closeDialog() {
      this.dialog = false
    }
  },
  validations: {
    selectedModel: { required },
    formatters: {
      company_name: { required },
      fantasy_name: { required },
      address: { required },
      cnpjs: { required },
      email: { required, email },
      hotsite: { required }
    }
  }
}
</script>
